/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import pt from '../../assets/img/testimonial/tauthor-1.png';



export default () => {
  
    return (
// className "owl-theme" is optional
<OwlCarousel className='owl-theme testimonail2-active   text-center testi-pad' items={3}   loop margin={10} >
<div className="testimonail__wrapper">
                    <div className="testimonail__wrapper__info d-flex align-items-center mb-30">
                      <div className="testimonail__wrapper__info__img ">
                      <img src={pt} alt=""  style={{height:'72px'}} />
                      </div>
                      <div className="testimonail__wrapper__info__author">
                        <h4>Daniel Groveria</h4>
                        <span>Student</span>
                      </div>
                      <div className="testimonail__wrapper__info__quotes">
                        <i className="flaticon-quote" />
                      </div>
                    </div>
                    <div className="testimonail__wrapper__content">
                      <p>Travellers from countries categorized under the high-risk list who are eligible to enter Germany, aged 12 and older, are obliged to present their vaccination certificates</p>
                      <div className="testimonail__wrapper__content__reviews">
                        <ul>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li>(Switzerland Visa)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="testimonail__wrapper">
                    <div className="testimonail__wrapper__info d-flex align-items-center mb-30">
                      <div className="testimonail__wrapper__info__img ">
                      <img src={pt} alt=""  style={{height:'72px'}} />
                      </div>
                      <div className="testimonail__wrapper__info__author">
                        <h4>Daniel Groveria</h4>
                        <span>Student</span>
                      </div>
                      <div className="testimonail__wrapper__info__quotes">
                        <i className="flaticon-quote" />
                      </div>
                    </div>
                    <div className="testimonail__wrapper__content">
                      <p>Travellers from countries categorized under the high-risk list who are eligible to enter Germany, aged 12 and older, are obliged to present their vaccination certificates</p>
                      <div className="testimonail__wrapper__content__reviews">
                        <ul>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li>(Switzerland Visa)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="testimonail__wrapper">
                    <div className="testimonail__wrapper__info d-flex align-items-center mb-30">
                      <div className="testimonail__wrapper__info__img ">
                      <img src={pt} alt=""  style={{height:'72px'}} />
                      </div>
                      <div className="testimonail__wrapper__info__author">
                        <h4>Daniel Groveria</h4>
                        <span>Student</span>
                      </div>
                      <div className="testimonail__wrapper__info__quotes">
                        <i className="flaticon-quote" />
                      </div>
                    </div>
                    <div className="testimonail__wrapper__content">
                      <p>Travellers from countries categorized under the high-risk list who are eligible to enter Germany, aged 12 and older, are obliged to present their vaccination certificates</p>
                      <div className="testimonail__wrapper__content__reviews">
                        <ul>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li>(Switzerland Visa)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="testimonail__wrapper">
                    <div className="testimonail__wrapper__info d-flex align-items-center mb-30">
                      <div className="testimonail__wrapper__info__img ">
                      <img src={pt} alt=""  style={{height:'72px'}} />
                      </div>
                      <div className="testimonail__wrapper__info__author">
                        <h4>Daniel Groveria</h4>
                        <span>Student</span>
                      </div>
                      <div className="testimonail__wrapper__info__quotes">
                        <i className="flaticon-quote" />
                      </div>
                    </div>
                    <div className="testimonail__wrapper__content">
                      <p>Travellers from countries categorized under the high-risk list who are eligible to enter Germany, aged 12 and older, are obliged to present their vaccination certificates</p>
                      <div className="testimonail__wrapper__content__reviews">
                        <ul>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li>(Switzerland Visa)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
</OwlCarousel>
 );
};

// <div className="countries-active  owl-carousel">
// <div className="countries-item img-top">
//   <div className="countries-item__top">
//     <div className="countries-item__top-img">
//       <img src="assets/img/country-img/c-2.jpg" alt="" />
//       <div className="countries-item__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="countries-item__bottom d-flex align-items-center">
//     <div className="countries-item__bottom-img mr-20">
//       <img src="assets/img/country-img/flag1.png" alt="" />
//     </div>
//     <div className="countries-item__bottom-content">
//       <h5 className="countries-item__bottom-content-title">
//         <a href="united-states.html">United States</a>
//       </h5> 
//       <p>58 Universities</p>
//     </div>
//   </div>
// </div> 
// <div className="countries-item img-top">
//   <div className="countries-item__top">
//     <div className="countries-item__top-img">
//       <img src="assets/img/country-img/c-2.jpg" alt="" />
//       <div className="countries-item__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="countries-item__bottom d-flex align-items-center">
//     <div className="countries-item__bottom-img mr-20">
//       <img src="assets/img/country-img/flag2.png" alt="" />
//     </div>
//     <div className="countries-item__bottom-content">
//       <h5 className="countries-item__bottom-content-title">
//         <a href="united-states.html">New Zealand</a>
//       </h5> 
//       <p>25 Universities</p>
//     </div>
//   </div>
// </div> 
// <div className="countries-item img-top">
//   <div className="countries-item__top">
//     <div className="countries-item__top-img">
//       <img src="assets/img/country-img/c-3.jpg" alt="" />
//       <div className="countries-item__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="countries-item__bottom d-flex align-items-center">
//     <div className="countries-item__bottom-img mr-20">
//       <img src="assets/img/country-img/flag3.png" alt="" />
//     </div>
//     <div className="countries-item__bottom-content">
//       <h5 className="countries-item__bottom-content-title">
//         <a href="united-states.html">United Kingdom</a>
//       </h5> 
//       <p>33 Universities</p>
//     </div>
//   </div>
// </div> 
// <div className="countries-item img-top">
//   <div className="countries-item__top">
//     <div className="countries-item__top-img">
//       <img src="assets/img/country-img/c-4.jpg" alt="" />
//       <div className="countries-item__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="countries-item__bottom d-flex align-items-center">
//     <div className="countries-item__bottom-img mr-20">
//       <img src="assets/img/country-img/flag4.png" alt="" />
//     </div>
//     <div className="countries-item__bottom-content">
//       <h5 className="countries-item__bottom-content-title">
//         <a href="united-states.html">Switzerland</a>
//       </h5> 
//       <p>38 Universities</p>
//     </div>
//   </div>
// </div> 
// <div className="countries-item img-top">
//   <div className="countries-item__top">
//     <div className="countries-item__top-img">
//       <img src="assets/img/country-img/c-5.jpg" alt="" />
//       <div className="countries-item__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="countries-item__bottom d-flex align-items-center">
//     <div className="countries-item__bottom-img mr-20">
//       <img src="assets/img/country-img/flag5.png" alt="" />
//     </div>
//     <div className="countries-item__bottom-content">
//       <h5 className="countries-item__bottom-content-title">
//         <a href="united-states.html">Australia</a>
//       </h5> 
//       <p>33 Universities</p>
//     </div>
//   </div>
// </div> 
// </div>