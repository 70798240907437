/* eslint-disable import/no-anonymous-default-export */
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import cTwo from '../../assets/img/hero2/hero-2.jpg';
import Two from '../../assets/img/hero2/hero-1.jpg';
import { useSwiper } from 'swiper/react';
// Import Swiper styles
   import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default () => {
  const swiper = useSwiper();
  return (
    
<section className="slider-area fix">
<div className="slider-active swiper-container">
  <div className="swiper-wrapper">
    <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
       scrollbar={{ draggable: false }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => swiper.slideNext()}
    >
      <SwiperSlide className="slider-height slider-height-2 d-flex align-items-center swiper-slide">

      <div className="slide-bg"  style={{backgroundImage: "url(" + cTwo + ")"}}/>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="aslider aslider-2 z-index">
              <span className="slider-top-text slider-top-text2" data-animation="fadeInUp" data-delay=".5s">Explore opportunities</span>
              <h2 className="aslider--title aslider--title-2 mb-20" data-animation="fadeInUp" data-delay=".7s">higher Study In <br /> Australia</h2>
              <p className="aslider--subtitle aslider--subtitle-2 mb-40" data-animation="fadeInUp" data-delay=".9s">300+ Best universities for higher education system is appreciated <br /> worldwide. Learning in universities or polytechnics </p>
              <div className="banner-content" data-animation="fadeInUp" data-delay=".9s">
                <form className="form" name="store" id="store" method="post" action="form.php">
                  <div className="form-inner">
                    <div className="input-group">
                      <select className="banner__input banner-niceselect">
                        <option value>Select Institute</option>
                        <option value>Option 1</option>
                        <option value>Option 2</option>
                        <option value>Option 3</option>
                        <option value>Option 4</option>
                        <option value>Option 5</option>
                      </select>
                      <input type="email" className="form-control" placeholder="Search here" />
                      <div className="input-group-append">
                        <button className="theme-btn theme-color" type="submit">Check Availability </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      </SwiperSlide>
      <SwiperSlide className="slider-height slider-height-2 d-flex align-items-center swiper-slide">

      <div className="slide-bg"  style={{backgroundImage: "url(" + Two + ")"}}/>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="aslider aslider-2 z-index">
              <span className="slider-top-text slider-top-text2" data-animation="fadeInUp" data-delay=".5s">Explore opportunities</span>
              <h2 className="aslider--title aslider--title-2 mb-20" data-animation="fadeInUp" data-delay=".7s">higher Study In <br /> Australia</h2>
              <p className="aslider--subtitle aslider--subtitle-2 mb-40" data-animation="fadeInUp" data-delay=".9s">300+ Best universities for higher education system is appreciated <br /> worldwide. Learning in universities or polytechnics </p>
              <div className="banner-content" data-animation="fadeInUp" data-delay=".9s">
                <form className="form" name="store" id="store" method="post" action="form.php">
                  <div className="form-inner">
                    <div className="input-group">
                      <select className="banner__input banner-niceselect">
                        <option value>Select Institute</option>
                        <option value>Option 1</option>
                        <option value>Option 2</option>
                        <option value>Option 3</option>
                        <option value>Option 4</option>
                        <option value>Option 5</option>
                      </select>
                      <input type="email" className="form-control" placeholder="Search here" />
                      <div className="input-group-append">
                        <button className="theme-btn theme-color" type="submit">Check Availability </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      </SwiperSlide>
      <button onClick={() => swiper.slideNext()}>Slide to the next slide</button>
    </Swiper>

    </div>
    </div>
</section>
  );
};
