import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import News from "./Pages/News";

import Faq from "./Pages/Faq";

import Countries from "./Pages/Countries";

import About from "./Pages/About";

import Services from "./Pages/Services";
import Contact from "./Pages/Contact";

import 'animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/scss/main.scss";
import "./assets/css/flaticon.css";

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/news" element={<News />} /> 
        <Route path="/faq" element={<Faq/>} /> 
        <Route path="/countries" element={<Countries/>} /> 
        <Route path="/about" element={<About/>} /> 
        <Route path="/services" element={<Services/>} /> 
        <Route path="/contact" element={<Contact/>} /> 
      </Routes>
    </div>
  );
};

export default App;
