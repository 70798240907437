import React from 'react';

import {Link } from "react-router-dom";
import cTwo from '../../assets/img/blog/blog1.jpg';
 import pt from '../../assets/img/about-us/ab-us.jpg';

const Main = () => {
  return (
    <main>
           <div>
       
        <div className="page-title__area pt-110" style={{backgroundImage: "url(" + pt + ")"}}>
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper text-center">
                  <h3 className="pb-100">News</h3>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadccrumb-bg">
            <ul className="breadcrumb justify-content-center pt-20 pb-20">
              <li className="bd-items"><Link to="/">Home</Link></li>
              <li className="bd-items bdritems">|</li>
              <li className="bd-items"><Link to="/news">News</Link></li>
            </ul>
          </nav>
        </div>
        {/* page title area end */}
        <section className="blog-area pt-120 pb-90 wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
          <div className="container">
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <article className="blog mb-30">
                  <div className="blog__thumb">
                    <a href="news-details.html"><img src={cTwo} alt="" /></a> 
                  </div>
                  <div className="blog__content">
                    <div className="blog-meta">
                      <span> <i className="fal fa-calendar-day" /><a href="news-details.html">02 feb 2018</a></span>
                      <span> <i className="far fa-user" />visapass</span>
                      <span><i className="far fa-comments" /><a href="news-details.html">(36)</a></span>
                    </div>
                    <div className="blog-text">
                      <h3 className="blog__content__title">
                        <a href="news-details.html">Web Development To Update React Hooks Cons</a> 
                      </h3>
                      <p>We have helped students, business persons, tourists, clients with medical needs......</p>
                      <div className="read-more">
                        <a href="news-details.html">Read More <i className="fal fa-long-arrow-right" /></a>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <article className="blog mb-30">
                  <div className="blog__thumb">
                    <a href="news-details.html"><img src={cTwo} alt="" /></a> 
                  </div>
                  <div className="blog__content">
                    <div className="blog-meta">
                      <span> <i className="fal fa-calendar-day" /><a href="news-details.html">02 feb 2018</a></span>
                      <span> <i className="far fa-user" />visapass</span>
                      <span><i className="far fa-comments" /><a href="news-details.html">(36)</a></span>
                    </div>
                    <div className="blog-text">
                      <h3 className="blog__content__title">
                        <a href="news-details.html">EU Commission Introduces New Erasmus+ App</a> 
                      </h3>
                      <p>We have helped students, business persons, tourists, clients with medical needs......</p>
                      <div className="read-more">
                        <a href="news-details.html">Read More <i className="fal fa-long-arrow-right" /></a>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <article className="blog mb-30">
                  <div className="blog__thumb">
                    <a href="news-details.html"><img src={cTwo} alt="" /></a> 
                  </div>
                  <div className="blog__content">
                    <div className="blog-meta">
                      <span> <i className="fal fa-calendar-day" /><a href="news-details.html">02 feb 2018</a></span>
                      <span> <i className="far fa-user" />visapass</span>
                      <span><i className="far fa-comments" /><a href="news-details.html">(36)</a></span>
                    </div>
                    <div className="blog-text">
                      <h3 className="blog__content__title">
                        <a href="news-details.html">France Extend Health Insurance  Requirement</a> 
                      </h3>
                      <p>We have helped students, business persons, tourists, clients with medical needs......</p>
                      <div className="read-more">
                        <a href="news-details.html">Read More <i className="fal fa-long-arrow-right" /></a>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <article className="blog mb-30">
                  <div className="blog__thumb">
                    <a href="news-details.html"><img src={cTwo} alt="" /></a> 
                  </div>
                  <div className="blog__content">
                    <div className="blog-meta">
                      <span> <i className="fal fa-calendar-day" /><a href="news-details.html">02 feb 2018</a></span>
                      <span> <i className="far fa-user" />visapass</span>
                      <span><i className="far fa-comments" /><a href="news-details.html">(36)</a></span>
                    </div>
                    <div className="blog-text">
                      <h3 className="blog__content__title">
                        <a href="news-details.html">Business English Spoken Course For Beginners</a> 
                      </h3>
                      <p>We have helped students, business persons, tourists, clients with medical needs......</p>
                      <div className="read-more">
                        <a href="news-details.html">Read More <i className="fal fa-long-arrow-right" /></a>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <article className="blog mb-30">
                  <div className="blog__thumb">
                    <a href="news-details.html"><img src={cTwo} alt="" /></a> 
                  </div>
                  <div className="blog__content">
                    <div className="blog-meta">
                      <span> <i className="fal fa-calendar-day" /><a href="news-details.html">02 feb 2018</a></span>
                      <span> <i className="far fa-user" />visapass</span>
                      <span><i className="far fa-comments" /><a href="news-details.html">(36)</a></span>
                    </div>
                    <div className="blog-text">
                      <h3 className="blog__content__title">
                        <a href="news-details.html">Native English Spoken Course For Business</a> 
                      </h3>
                      <p>We have helped students, business persons, tourists, clients with medical needs......</p>
                      <div className="read-more">
                        <a href="news-details.html">Read More <i className="fal fa-long-arrow-right" /></a>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <article className="blog mb-30">
                  <div className="blog__thumb">
                    <a href="news-details.html"><img src={cTwo} alt="" /></a> 
                  </div>
                  <div className="blog__content">
                    <div className="blog-meta">
                      <span> <i className="fal fa-calendar-day" /><a href="news-details.html">02 feb 2018</a></span>
                      <span> <i className="far fa-user" />visapass</span>
                      <span><i className="far fa-comments" /><a href="news-details.html">(36)</a></span>
                    </div>
                    <div className="blog-text">
                      <h3 className="blog__content__title">
                        <a href="news-details.html">Spanish Language Course For Jobs Seekers</a> 
                      </h3>
                      <p>We have helped students, business persons, tourists, clients with medical needs......</p>
                      <div className="read-more">
                        <a href="news-details.html">Read More <i className="fal fa-long-arrow-right" /></a>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>
      
        {/* JS here */}
      </div>
        </main>
  );
};

export default Main;
