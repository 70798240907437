

import { UncontrolledAccordion,AccordionItem,AccordionHeader, AccordionBody, } from 'reactstrap';
const Accordion = () => {
  return <>
  <div>
  <UncontrolledAccordion
    defaultOpen={[
      '1',
      '2'
    ]}
    stayOpen
  >
      <div className="tab-content mb-30" id="pills-tabContent">

      <div className="faq-content faq-white">
    <AccordionItem>
      <AccordionHeader targetId="1">
      How long does it take for a Transit Visa to process?
      </AccordionHeader>
      <AccordionBody accordionId="1">
    
        The European business visa is mainly for people who want to participate in business meetings, conferences in Europe. Visa holders are not allowed to work or seek employment in Europe. Individuals and circumstances influence how long it takes to apply for a business visa.
      </AccordionBody>
    </AccordionItem>
    <AccordionItem>
      <AccordionHeader targetId="2">
     
      What is the purpose of the United States Business visa?
                               
      </AccordionHeader>
      <AccordionBody accordionId="2">
      The European business visa is mainly for people who want to participate in business meetings, conferences in Europe. Visa holders are not allowed to work or seek employment in Europe. Individuals and circumstances influence how long it takes to apply for a business visa.
      </AccordionBody>
    </AccordionItem>
    <AccordionItem>
      <AccordionHeader targetId="3">

      If the applicant is intending to stay more than 3 months?
                               
      </AccordionHeader>
      <AccordionBody accordionId="3">
      The European business visa is mainly for people who want to participate in business meetings, conferences in Europe. Visa holders are not allowed to work or seek employment in Europe. Individuals and circumstances influence how long it takes to apply for a business visa.
      </AccordionBody>
    </AccordionItem>
    <AccordionItem>
      <AccordionHeader targetId="4">

      What are the important things to know as a Transit Visa applicant?
                                
      </AccordionHeader>
      <AccordionBody accordionId="4">
      The European business visa is mainly for people who want to participate in business meetings, conferences in Europe. Visa holders are not allowed to work or seek employment in Europe. Individuals and circumstances influence how long it takes to apply for a business visa. </AccordionBody>
    </AccordionItem>
    <AccordionItem>
      <AccordionHeader targetId="5">

      How do I read and understand my visa?
                                
      </AccordionHeader>
      <AccordionBody accordionId="5">
      The European business visa is mainly for people who want to participate in business meetings, conferences in Europe. Visa holders are not allowed to work or seek employment in Europe. Individuals and circumstances influence how long it takes to apply for a business visa. </AccordionBody>
    </AccordionItem>
    <AccordionItem>
      <AccordionHeader targetId="5">

      Can I bring friends and family with me, if I am to be interviewed?
                                
      </AccordionHeader>
      <AccordionBody accordionId="5">
      The European business visa is mainly for people who want to participate in business meetings, conferences in Europe. Visa holders are not allowed to work or seek employment in Europe. Individuals and circumstances influence how long it takes to apply for a business visa. </AccordionBody>
    </AccordionItem>
    </div>   </div>
  </UncontrolledAccordion>

</div>
  </>;
};

export default Accordion;