
import Footer from '../../TheamLayout/Footer';
import Header from '../../TheamLayout/Header';
import Main from './Main'

const Faq = () => {
  return <>
   <Header/>
  <Main/>
  <Footer/>
  </>;
};

export default Faq;
