import React from 'react';

import {Link } from "react-router-dom";
import cTwo from '../../assets/img/blog/blog1.jpg';
//  import {Tabs,Tab,Sonnet } from "react-bootstrap";
 import Tabs from './Tabs'
 
import seal from '../../assets/img/country-img/flag1.png';
import steps from '../../assets/img/step/steps-br.png';
import mapp from '../../assets/img/team/map-.png';

import partnersOne from '../../assets/img/country-img/c-8.jpg';
 import partnersTwo from '../../assets/img/country-img/c-1.jpg';
 
import cfou from '../../assets/img/country-img/c-5.jpg';
 
 import pt from '../../assets/img/about-us/ab-us.jpg';
 import Slide from './Sliders-two'
const Main = () => {
  return (
    <main>
  <div>
        {/* Fullscreen search */}
        <div className="search-wrap">
          <div className="search-inner">
            <i className="fal fa-times search-close" id="search-close" />
            <div className="search-cell">
              <form method="get">
                <div className="search-field-holder">
                  <input type="search" className="main-search-input" placeholder="Search Entire Store..." />
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* end fullscreen search */}
        {/* header area end here */}
        {/* page title area start */}
        <div className="page-title__area pt-110" style={{backgroundImage: "url(" + pt + ")"}}>
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper text-center">
                  <h3 className="pb-100">Countries</h3>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadccrumb-bg">
            <ul className="breadcrumb justify-content-center pt-20 pb-20">
            <li className="bd-items"><Link to="/">Home</Link></li>
              <li className="bd-items bdritems">|</li>
              <li className="bd-items"> <Link to="/countries">Countries</Link></li>
            </ul>
          </nav>
        </div>
        {/* page title area end */}
        <main>
          <div className="most-countries pt-100 pb-90">
            <div className="container">
              <div className="row d-flex align-items-center">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="section_title_wrapper mb-25">                     
                    <h2 className="section-title">
                      Most Popular Countries
                    </h2>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mb-30 dflx-content-start d-flex justify-content-end">
                  <select className="contact__input mostpppr-niceselect">
                    <option value>All Countries</option>
                    <option value>United States</option>
                    <option value>New Zealand</option>
                    <option value>United Kingdom</option>
                    <option value>Switzerland</option>
                    <option value>Australia </option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                      <img src={cfou} alt="" />
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html"><i className="fal fa-plus" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag1.png" alt="" />
                      </div>
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">United States</a>
                        </h5> 
                        <p>58 Universities</p>
                      </div>
                    </div>
                  </div> 
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                        <img src={cfou} alt="" />
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html"><i className="fal fa-plus" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag2.png" alt="" />
                      </div>
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">New Zealand</a>
                        </h5> 
                        <p>25 Universities</p>
                      </div>
                    </div>
                  </div> 
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                      <img src={cfou} alt="" />
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html"><i className="fal fa-plus" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag3.png" alt="" />
                      </div>
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">United Kingdom</a>
                        </h5> 
                        <p>33 Universities</p>
                      </div>
                    </div>
                  </div> 
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                      <img src={cfou} alt="" />
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html"><i className="fal fa-plus" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag4.png" alt="" />
                      </div>
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">Switzerland</a>
                        </h5> 
                        <p>38 Universities</p>
                      </div>
                    </div>
                  </div> 
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                      <img src={cfou} alt="" />
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html"><i className="fal fa-plus" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag5.png" alt="" />
                      </div>
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">Australia	</a>
                        </h5> 
                        <p>33 Universities</p>
                      </div>
                    </div>
                  </div> 
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                      <img src={cfou} alt="" />
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html"><i className="fal fa-plus" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag6.png" alt="" />
                      </div>
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">Bangladesh</a>
                        </h5> 
                        <p>25 Universities</p>
                      </div>
                    </div>
                  </div> 
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                      <img src={cfou} alt="" />
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html"><i className="fal fa-plus" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag7.png" alt="" />
                      </div>
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">Canada</a>
                        </h5> 
                        <p>33 Universities</p>
                      </div>
                    </div>
                  </div> 
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                      <img src={cfou} alt="" />
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html"><i className="fal fa-plus" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag8.png" alt="" />
                      </div>
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">China</a>
                        </h5> 
                        <p>38 Universities</p>
                      </div>
                    </div>
                  </div> 
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                      <img src={cfou} alt="" />
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html"><i className="fal fa-plus" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag9.png" alt="" />
                      </div>
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">Denmark</a>
                        </h5> 
                        <p>33 Universities</p>
                      </div>
                    </div>
                  </div> 
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                      <img src={cfou} alt="" />
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html"><i className="fal fa-plus" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag10.png" alt="" />
                      </div>
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">France</a>
                        </h5> 
                        <p>25 Universities</p>
                      </div>
                    </div>
                  </div> 
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                      <img src={cfou} alt="" />
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html"><i className="fal fa-plus" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag11.png" alt="" />
                      </div>
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">Germany</a>
                        </h5> 
                        <p>33 Universities</p>
                      </div>
                    </div>
                  </div> 
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="countries-item img-top">
                    <div className="countries-item__top">
                      <div className="countries-item__top-img">
                      <img src={cfou} alt="" />
                        <div className="countries-item__top-img-link">
                          <a href="united-states.html"><i className="fal fa-plus" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="countries-item__bottom d-flex align-items-center">
                      <div className="countries-item__bottom-img mr-20">
                        <img src="assets/img/country-img/flag12.png" alt="" />
                      </div>
                      <div className="countries-item__bottom-content">
                        <h5 className="countries-item__bottom-content-title">
                          <a href="united-states.html">Norway</a>
                        </h5> 
                        <p>38 Universities</p>
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div> 
          {/* abbrand-area start */}
          <div className="abbrand-area pb-120 wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
            <div className="container">
            <Slide/>
            </div>
          </div>
          {/* abbrand-area end */}
        </main>
      </div>
        </main>
  );
};

export default Main;
