import React from 'react';

import {Link } from "react-router-dom";
import pt from '../../assets/img/blog/t.jpg';
//  import pt from '../../asset/img/team/t.jpg';
//  import {Tabs,Tab,Sonnet } from "react-bootstrap";

import about from '../../assets/img/about-us/ab-us.jpg';

import abouttwo from '../../assets/img/about-2/about2.jpg';

import aboutwo from '../../assets/img/about-2/about2-1.jpg';

import seal from '../../assets/img/about-2/seal.png';

import abbg from '../../assets/img/about/abbg.jpg';

import pth from '../../assets/img/about/pth.png';

import abfact from '../../assets/img/ab-fact/abfact.jpg';

import ab from '../../assets/img/about-us/ab-m.jpg';

import SliderOne from './Sliders-three'
import Slide from './Sliders-two'
 import Tabs from './Tabs'
const Main = () => {
  return (
  
<div>
        {/* Fullscreen search */}
        <div className="search-wrap">
          <div className="search-inner">
            <i className="fal fa-times search-close" id="search-close" />
            <div className="search-cell">
              <form method="get">
                <div className="search-field-holder">
                  <input type="search" className="main-search-input" placeholder="Search Entire Store..." />
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* end fullscreen search */}
        {/* header area end here */}
        {/* page title area start */}
        <div className="page-title__area pt-110"  style={{backgroundImage: "url(" + about + ")"}}>
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper text-center">
                  <h3 className="pb-100">About Us </h3>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadccrumb-bg">
            <ul className="breadcrumb justify-content-center pt-20 pb-20">
            <li className="bd-items"><Link to="/">Home</Link></li>
              <li className="bd-items bdritems">|</li>
              <li className="bd-items"> <Link to="/about"> About Us</Link></li>
            </ul>
          </nav>
        </div>
        {/* page title area end */}
        <main>
          {/* About-us area start here */}
          <section className="about-area-2 pt-120 pb-80">
            <div className="container">
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 mb-30 wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
                  <div className="about2-left d-flex">
                    <div className="about2-left__img1 mr-10">
                      <img src={abouttwo} alt="" />
                    </div>
                    <div className="about2-left__img2">
                      <img   src={aboutwo} alt="" />
                      <div className="about2-left__info d-flex align-items-center">
                        <div className="about2-left__info__left mr-15">
                          <img  src={seal}seal alt="" />
                        </div>
                        <div className="about2-left__info__right">
                          <h4>ISO Certified</h4>
                          <p>1990-2000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 mb-30 wow fadeInUp" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp'}}>
                  <div className="section_title_wrapper">
                    <span className="subtitle">
                      About visapass
                    </span>                       
                    <h2 className="section-title about-span mb-30">
                      <span>25+</span>  Years of Your Trust <br /> and Recommendation
                    </h2>
                    <div className="section_title_wrapper__about-content mb-40">
                      <p>For the last 25 years, We have helped students, business persons,clients with medical needs. There are many variations tourists, clients with medical needs. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration tourists, clients with medical needs. There are many variations</p>
                    </div>
                  </div>
                  <div className="about-trust">
                    <div className="row mb-10">
                      <div className="col-lg-6">
                        <div className="about2__item d-flex  mb-20">
                          <div className="about2__icon">
                            <i className="flaticon-fair-trade" />
                          </div>
                          <div className="about2__content">
                            <h4>Trusted by Millions</h4>
                            <p>Most trusted &amp; recommended by millions of students</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="about2__item d-flex  mb-20">
                          <div className="about2__icon">
                            <i className="flaticon-trophy" />
                          </div>
                          <div className="about2__content">
                            <h4>Awards Winner</h4>
                            <p>Most trusted &amp; recommended by millions of students</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* About-us area end here */}
          {/* Histry Tabs area start here */}
          <div className="histry-area pt-110 pb-90" style={{backgroundImage: "url(" + abbg + ")"}}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xxl-10">
                  <div className="section_title_wrapper text-center wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}>                     
                    <h2 className="section-title white-color">
                      Visapass Carries 25+ Year's <br /> Awesome History
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Histry Tabs area end here */}
          {/* About- Tabs area start here */}
          <div className="ab-tabs pb-70">
            <div className="abtb-hr1">
              <span />
            </div>
            <div className="abtb-pth">
              <img src={pth}  alt="" />
            </div>
            <div className="container">
              <div className="row ">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                  <div className="price-tab pb-130 abtab-top">
                    <ul className="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link nav-radius active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">1990 - 1995</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">1996 - 2000</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-contact-tab1" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">2001 - 2005</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-contact-tab2" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">2006 - 2010</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link navr-radius" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">2011 - 2020</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                  <div className="row ">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="abtb-content text-right pr-105 mb-45">
                        <div className="abtbs-round">
                          <span />
                        </div>
                        <div className="abtb-mbr">
                          <span />
                        </div>
                        <span>22 jan 1995</span>
                        <h4 className="abtb-title">
                          Started Journey in New York
                        </h4>
                        <p>Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation.</p>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="abtb-content pl-105 mb-45">
                        <span>25 Aug 1994</span>
                        <h4 className="abtb-title">
                          First Trophy Winner in World
                        </h4>
                        <p>Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation.</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="abtb-content text-right pr-105 mb-45">
                        <div className="abtbs-round">
                          <span />
                        </div>
                        <div className="abtb-mbr">
                          <span />
                        </div>
                        <span>22 jan 1995</span>
                        <h4 className="abtb-title">
                          Started Journey in New York
                        </h4>
                        <p>Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation.</p>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="abtb-content pl-105 mb-45">
                        <span>25 Aug 1994</span>
                        <h4 className="abtb-title">
                          First Trophy Winner in World
                        </h4>
                        <p>Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <div className="row ">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="abtb-content text-right pr-105 mb-45">
                        <div className="abtbs-round">
                          <span />
                        </div>
                        <div className="abtb-mbr">
                          <span />
                        </div>
                        <span>22 jan 1995</span>
                        <h4 className="abtb-title">
                          Started Journey in New York
                        </h4>
                        <p>Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation.</p>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="abtb-content pl-105 mb-45">
                        <span>25 Aug 1994</span>
                        <h4 className="abtb-title">
                          First Trophy Winner in World
                        </h4>
                        <p>Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation.</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="abtb-content text-right pr-105 mb-45">
                        <div className="abtbs-round">
                          <span />
                        </div>
                        <div className="abtb-mbr">
                          <span />
                        </div>
                        <span>22 jan 1995</span>
                        <h4 className="abtb-title">
                          Started Journey in New York
                        </h4>
                        <p>Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation.</p>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="abtb-content pl-105 mb-45">
                        <span>25 Aug 1994</span>
                        <h4 className="abtb-title">
                          First Trophy Winner in World
                        </h4>
                        <p>Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                  <div className="row ">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="abtb-content text-right pr-105 mb-45">
                        <div className="abtbs-round">
                          <span />
                        </div>
                        <div className="abtb-mbr">
                          <span />
                        </div>
                        <span>22 jan 1995</span>
                        <h4 className="abtb-title">
                          Started Journey in New York
                        </h4>
                        <p>Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation.</p>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="abtb-content pl-105 mb-45">
                        <span>25 Aug 1994</span>
                        <h4 className="abtb-title">
                          First Trophy Winner in World
                        </h4>
                        <p>Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation.</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="abtb-content text-right pr-105 mb-45">
                        <div className="abtbs-round">
                          <span />
                        </div>
                        <div className="abtb-mbr">
                          <span />
                        </div>
                        <span>22 jan 1995</span>
                        <h4 className="abtb-title">
                          Started Journey in New York
                        </h4>
                        <p>Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation.</p>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                      <div className="abtb-content pl-105 mb-45">
                        <span>25 Aug 1994</span>
                        <h4 className="abtb-title">
                          First Trophy Winner in World
                        </h4>
                        <p>Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* About- Tabs area end here */}
          {/* Ab-fact-area start */}
          <div className="abfact-area pt-80 pb-170" style={{backgroundImage: "url(" + abfact + ")"}} >
            <div className="container">
              <div className="row mb-20">
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
                  <div className="fact fact-2 abfact-items text-center">
                    <h1 className="counter-count"><span className="counter">25</span>k+</h1>
                    <span>Happy Clients &amp; Students</span>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30 ">
                  <div className="fact fact-2 abfact-items text-center ">
                    <h1 className="counter-count"><span className="counter">80</span>+</h1>
                    <span>Countries Affiliation</span>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
                  <div className="fact fact-2 abfact-items text-center ">
                    <h1 className="counter">360</h1>
                    <span>Top University Partner</span>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
                  <div className="fact fact-2 abfact-items text-center ">
                    <h1 className="counter-count"><span className="counter">23</span>k+</h1>
                    <span>Visa &amp; Immigration</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Ab-fact-area end */}
          {/* intro-area start */}
          <section className="intro-area">
            <div className="container">
              <div className="row abintro-top g-0">
                <div className="col-xxl-6 col-xl-6 col-lg-6 d-flex align-items-center wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
                  <div className="section_title_wrapper pl-50 pr-70 wow fadeInUp" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp'}}>
                    <span className="subtitle">
                      Our Missions
                    </span>                       
                    <h2 className="section-title">
                      We Journey The Global Business to Ensuring The Guarantee
                    </h2>
                    <p className="pt-30 pb-30 ">For the last 25 years, We have helped students, business persons, tourists, clients with medical needs. There are many variations of passages of Lorem Ipsum available.</p>
                    <div className="abinfro-btn d-flex align-items-center">
                      <a href="contact.html" className="theme-btn">See Packages</a>
                      <a href="#0" className="btn-download"> <i className="fal fa-download" /> Download Brochure</a>
                    </div>
                  </div>
                </div> 
                <div className="col-xxl-6 col-xl-6 col-lg-6 wow fadeInUp" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp'}}>
                  <div className="intro-right">
                    <img src={ab} alt="" />
                    <div className="intro-btn">
                      <a className="play-btn popup-video" href="https://www.youtube.com/watch?v=pNje3bWz7V8"><i className="flaticon-play-button" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* intro-area end */}
          {/* Testimonail start */}
          <section className="testimonail-area pt-110 pb-190">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xxl-10">
                  <div className="section_title_wrapper text-center mb-50">
                    <span className="subtitle">
                      Testimonials
                    </span>                       
                    <h2 className="section-title">
                      What Clients Say About Us and <br /> Our Services
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row">
              <SliderOne/>
                <div className="textimonail-active owl-carousel">
                  <div className="testimonail__wrapper">
                    <div className="testimonail__wrapper__info d-flex align-items-center mb-30">
                      <div className="testimonail__wrapper__info__img ">
                      <img src={pt} alt="" />
                      </div>
                      <div className="testimonail__wrapper__info__author">
                        <h4>Daniel Groveria</h4>
                        <span>Student</span>
                      </div>
                      <div className="testimonail__wrapper__info__quotes">
                        <i className="flaticon-quote" />
                      </div>
                    </div>
                    <div className="testimonail__wrapper__content">
                      <p>Travellers from countries categorized under the high-risk list who are eligible to enter Germany, aged 12 and older, are obliged to present their vaccination certificates</p>
                      <div className="testimonail__wrapper__content__reviews">
                        <ul>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li>(Switzerland Visa)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="testimonail__wrapper">
                    <div className="testimonail__wrapper__info d-flex align-items-center mb-30">
                      <div className="testimonail__wrapper__info__img ">
                      <img src={pt} alt="" />
                      </div>
                      <div className="testimonail__wrapper__info__author">
                        <h4>Daniel Groveria</h4>
                        <span>Student</span>
                      </div>
                      <div className="testimonail__wrapper__info__quotes">
                        <i className="flaticon-quote" />
                      </div>
                    </div>
                    <div className="testimonail__wrapper__content">
                      <p>Travellers from countries categorized under the high-risk list who are eligible to enter Germany, aged 12 and older, are obliged to present their vaccination certificates</p>
                      <div className="testimonail__wrapper__content__reviews ">
                        <ul>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li>(Switzerland Visa)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="testimonail__wrapper">
                    <div className="testimonail__wrapper__info d-flex align-items-center mb-30">
                      <div className="testimonail__wrapper__info__img ">
                      <img src={pt} alt="" />
                      </div>
                      <div className="testimonail__wrapper__info__author">
                        <h4>Daniel Groveria</h4>
                        <span>Student</span>
                      </div>
                      <div className="testimonail__wrapper__info__quotes">
                        <i className="flaticon-quote" />
                      </div>
                    </div>
                    <div className="testimonail__wrapper__content">
                      <p>Travellers from countries categorized under the high-risk list who are eligible to enter Germany, aged 12 and older, are obliged to present their vaccination certificates</p>
                      <div className="testimonail__wrapper__content__reviews">
                        <ul>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li>(Switzerland Visa)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="testimonail__wrapper">
                    <div className="testimonail__wrapper__info d-flex align-items-center mb-30">
                      <div className="testimonail__wrapper__info__img ">
                      <img src={pt} alt="" />
                      </div>
                      <div className="testimonail__wrapper__info__author">
                        <h4>Daniel Groveria</h4>
                        <span>Student</span>
                      </div>
                      <div className="testimonail__wrapper__info__quotes">
                        <i className="flaticon-quote" />
                      </div>
                    </div>
                    <div className="testimonail__wrapper__content">
                      <p>Travellers from countries categorized under the high-risk list who are eligible to enter Germany, aged 12 and older, are obliged to present their vaccination certificates</p>
                      <div className="testimonail__wrapper__content__reviews">
                        <ul>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li>(Switzerland Visa)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="testimonail__wrapper">
                    <div className="testimonail__wrapper__info d-flex align-items-center mb-30">
                      <div className="testimonail__wrapper__info__img ">
                      <img src={pt} alt="" />
                      </div>
                      <div className="testimonail__wrapper__info__author">
                        <h4>Daniel Groveria</h4>
                        <span>Student</span>
                      </div>
                      <div className="testimonail__wrapper__info__quotes">
                        <i className="flaticon-quote" />
                      </div>
                    </div>
                    <div className="testimonail__wrapper__content">
                      <p>Travellers from countries categorized under the high-risk list who are eligible to enter Germany, aged 12 and older, are obliged to present their vaccination certificates</p>
                      <div className="testimonail__wrapper__content__reviews">
                        <ul>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li>(Switzerland Visa)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="testimonail__wrapper">
                    <div className="testimonail__wrapper__info d-flex align-items-center mb-30">
                      <div className="testimonail__wrapper__info__img ">
                      <img src={pt} alt="" />
                      </div>
                      <div className="testimonail__wrapper__info__author">
                        <h4>Daniel Groveria</h4>
                        <span>Student</span>
                      </div>
                      <div className="testimonail__wrapper__info__quotes">
                        <i className="flaticon-quote" />
                      </div>
                    </div>
                    <div className="testimonail__wrapper__content">
                      <p>Travellers from countries categorized under the high-risk list who are eligible to enter Germany, aged 12 and older, are obliged to present their vaccination certificates</p>
                      <div className="testimonail__wrapper__content__reviews">
                        <ul>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li>(Switzerland Visa)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="testimonail__wrapper">
                    <div className="testimonail__wrapper__info d-flex align-items-center mb-30">
                      <div className="testimonail__wrapper__info__img ">
                        <img src={pt} alt="" />
                      </div>
                      <div className="testimonail__wrapper__info__author">
                        <h4>Daniel Groveria</h4>
                        <span>Student</span>
                      </div>
                      <div className="testimonail__wrapper__info__quotes">
                        <i className="flaticon-quote" />
                      </div>
                    </div>
                    <div className="testimonail__wrapper__content">
                      <p>Travellers from countries categorized under the high-risk list who are eligible to enter Germany, aged 12 and older, are obliged to present their vaccination certificates</p>
                      <div className="testimonail__wrapper__content__reviews">
                        <ul>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li><i className="fas fa-star" /></li>
                          <li>(Switzerland Visa)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Testimonail end */}
          {/* Team area start */}
          <section className="team-area grey-soft-bg pt-110 pb-80" style={{backgroundImage: 'url(assets/img/testimonial/tsti1-bg.jpg)'}}>
            <div className="container">
              <div className="row justify-content-center wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
                <div className="col-xxl-10">
                  <div className="section_title_wrapper text-center mb-50">
                    <span className="subtitle">
                      Authorized Agents
                    </span>                       
                    <h2 className="section-title">
                      Our Agents Who are <br /> Dedicatedly Working With Us
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
                  <div className="team text-center mb-30">
                    <div className="team__thumb team__thumb-2 mb-25">
                    <img src={pt} alt="" />
                      <div className="team__thumb-info">
                        <div className="team-social">
                          <a href="#"><i className="fab fa-facebook-f" /></a>
                          <a href="#"><i className="fab fa-twitter" /></a>
                          <a href="#"><i className="fab fa-instagram" /></a>
                          <a href="#"><i className="fab fa-youtube" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="team__text">
                      <h3 className="team__text-title">
                        <a href="team-details.html">Marida Tohaman</a> 
                      </h3>
                      <span>CEO, Visapass</span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp'}}>
                  <div className="team text-center mb-30">
                    <div className="team__thumb team__thumb-2 mb-25">
                    <img src={pt} alt="" />
                      <div className="team__thumb-info">
                        <div className="team-social">
                          <a href="#"><i className="fab fa-facebook-f" /></a>
                          <a href="#"><i className="fab fa-twitter" /></a>
                          <a href="#"><i className="fab fa-instagram" /></a>
                          <a href="#"><i className="fab fa-youtube" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="team__text">
                      <h3 className="team__text-title">
                        <a href="team-details.html">Daniel Hasmass</a> 
                      </h3>
                      <span>Sr. Consultant</span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s" style={{visibility: 'visible', animationDelay: '0.7s', animationName: 'fadeInUp'}}>
                  <div className="team text-center mb-30">
                    <div className="team__thumb team__thumb-2 mb-25">
                    <img src={pt} alt="" />
                      <div className="team__thumb-info">
                        <div className="team-social">
                          <a href="#"><i className="fab fa-facebook-f" /></a>
                          <a href="#"><i className="fab fa-twitter" /></a>
                          <a href="#"><i className="fab fa-instagram" /></a>
                          <a href="#"><i className="fab fa-youtube" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="team__text">
                      <h3 className="team__text-title">
                        <a href="team-details.html">Narayan Kamora</a> 
                      </h3>
                      <span>Senior Lawyer</span>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.9s" style={{visibility: 'visible', animationDelay: '0.9s', animationName: 'fadeInUp'}}>
                  <div className="team text-center mb-30">
                    <div className="team__thumb team__thumb-2 mb-25">
                    <img src={pt} alt="" />
                      <div className="team__thumb-info">
                        <div className="team-social">
                          <a href="#"><i className="fab fa-facebook-f" /></a>
                          <a href="#"><i className="fab fa-twitter" /></a>
                          <a href="#"><i className="fab fa-instagram" /></a>
                          <a href="#"><i className="fab fa-youtube" /></a>
                        </div>
                      </div>
                    </div>
                    <div className="team__text">
                      <h3 className="team__text-title">
                        <a href="team-details.html">Marida Tohaman</a> 
                      </h3>
                      <span>Manager</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Team area end */}
          {/* abbrand-area start */}
          <div className="abbrand-area pt-120 pb-120 wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
            <div className="container">
            <Slide/>
            </div>
          </div>
          {/* abbrand-area end */}
        </main>
      </div>
         
  );
};

export default Main;
