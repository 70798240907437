/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import cTwo from '../../assets/img/country-img/c-2.jpg';
import cthree from '../../assets/img/country-img/c-3.jpg';

import cfour from '../../assets/img/country-img/c-4.jpg';

import cfou from '../../assets/img/country-img/c-5.jpg';

import flag1 from '../../assets/img/country-img/flag1.png';

export default () => {
  
    return (
// className "owl-theme" is optional
<OwlCarousel className='owl-theme countries-active ' items={4}   loop margin={10} nav>
    <div class='item countries-item img-top pl-4 pr-4'>
    <div className="countries-item__top">
    <div className="countries-item__top-img">
      <img src={cTwo}  alt="" />
      <div className="countries-item__top-img-link">
        <a href="united-states.html"><i className="fal fa-plus" /></a>
      </div>
    </div>
  </div>
  <div className="countries-item__bottom d-flex align-items-center">
    <div className="countries-item__bottom-img mr-20">
      <img src={flag1} alt="" />
    </div>
    <div className="countries-item__bottom-content">
      <h5 className="countries-item__bottom-content-title">
        <a href="united-states.html">United States</a>
      </h5> 
      <p>58 Universities</p>
    </div>
  </div>
    </div>
    <div class='item countries-item img-top pl-4 pr-4'>
    <div className="countries-item__top">
    <div className="countries-item__top-img">
      <img src={cthree}  alt="" />
      <div className="countries-item__top-img-link">
        <a href="united-states.html"><i className="fal fa-plus" /></a>
      </div>
    </div>
  </div>
  <div className="countries-item__bottom d-flex align-items-center">
    <div className="countries-item__bottom-img mr-20">
      <img src={flag1} alt="" />
    </div>
    <div className="countries-item__bottom-content">
      <h5 className="countries-item__bottom-content-title">
        <a href="united-states.html">United States</a>
      </h5> 
      <p>58 Universities</p>
    </div>
  </div>
    </div>
    <div class='item countries-item img-top pl-4 pr-4'>
    <div className="countries-item__top">
    <div className="countries-item__top-img">
      <img src={cfour}  alt="" />
      <div className="countries-item__top-img-link">
        <a href="united-states.html"><i className="fal fa-plus" /></a>
      </div>
    </div>
  </div>
  <div className="countries-item__bottom d-flex align-items-center">
    <div className="countries-item__bottom-img mr-20">
      <img src={flag1} alt="" />
    </div>
    <div className="countries-item__bottom-content">
      <h5 className="countries-item__bottom-content-title">
        <a href="united-states.html">United States</a>
      </h5> 
      <p>58 Universities</p>
    </div>
  </div>
    </div>
    <div class='item countries-item img-top pl-4 pr-4'>
    <div className="countries-item__top">
    <div className="countries-item__top-img">
      <img src={cfou}  alt="" />
      <div className="countries-item__top-img-link">
        <a href="united-states.html"><i className="fal fa-plus" /></a>
      </div>
    </div>
  </div>
  <div className="countries-item__bottom d-flex align-items-center">
    <div className="countries-item__bottom-img mr-20">
      <img src={flag1} alt="" />
    </div>
    <div className="countries-item__bottom-content">
      <h5 className="countries-item__bottom-content-title">
        <a href="united-states.html">United States</a>
      </h5> 
      <p>58 Universities</p>
    </div>
  </div>
    </div>
    <div class='item countries-item img-top pl-4 pr-4'>
    <div className="countries-item__top">
    <div className="countries-item__top-img">
      <img src={cTwo}  alt="" />
      <div className="countries-item__top-img-link">
        <a href="united-states.html"><i className="fal fa-plus" /></a>
      </div>
    </div>
  </div>
  <div className="countries-item__bottom d-flex align-items-center">
    <div className="countries-item__bottom-img mr-20">
      <img src={flag1} alt="" />
    </div>
    <div className="countries-item__bottom-content">
      <h5 className="countries-item__bottom-content-title">
        <a href="united-states.html">United States</a>
      </h5> 
      <p>58 Universities</p>
    </div>
  </div>
    </div>
    <div class='item countries-item img-top pl-4 pr-4'>
    <div className="countries-item__top">
    <div className="countries-item__top-img">
      <img src={cthree}  alt="" />
      <div className="countries-item__top-img-link">
        <a href="united-states.html"><i className="fal fa-plus" /></a>
      </div>
    </div>
  </div>
  <div className="countries-item__bottom d-flex align-items-center">
    <div className="countries-item__bottom-img mr-20">
      <img src={flag1} alt="" />
    </div>
    <div className="countries-item__bottom-content">
      <h5 className="countries-item__bottom-content-title">
        <a href="united-states.html">United States</a>
      </h5> 
      <p>58 Universities</p>
    </div>
  </div>
    </div>
    <div class='item countries-item img-top pl-4 pr-4'>
    <div className="countries-item__top">
    <div className="countries-item__top-img">
      <img src={cfour}  alt="" />
      <div className="countries-item__top-img-link">
        <a href="united-states.html"><i className="fal fa-plus" /></a>
      </div>
    </div>
  </div>
  <div className="countries-item__bottom d-flex align-items-center">
    <div className="countries-item__bottom-img mr-20">
      <img src={flag1} alt="" />
    </div>
    <div className="countries-item__bottom-content">
      <h5 className="countries-item__bottom-content-title">
        <a href="united-states.html">United States</a>
      </h5> 
      <p>58 Universities</p>
    </div>
  </div>
    </div>
    <div class='item countries-item img-top pl-4 pr-4'>
    <div className="countries-item__top">
    <div className="countries-item__top-img">
      <img src={cfou}  alt="" />
      <div className="countries-item__top-img-link">
        <a href="united-states.html"><i className="fal fa-plus" /></a>
      </div>
    </div>
  </div>
  <div className="countries-item__bottom d-flex align-items-center">
    <div className="countries-item__bottom-img mr-20">
      <img src={flag1} alt="" />
    </div>
    <div className="countries-item__bottom-content">
      <h5 className="countries-item__bottom-content-title">
        <a href="united-states.html">United States</a>
      </h5> 
      <p>58 Universities</p>
    </div>
  </div>
    </div>
</OwlCarousel>
 );
};

// <div className="countries-active  owl-carousel">
// <div className="countries-item img-top">
//   <div className="countries-item__top">
//     <div className="countries-item__top-img">
//       <img src="assets/img/country-img/c-2.jpg" alt="" />
//       <div className="countries-item__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="countries-item__bottom d-flex align-items-center">
//     <div className="countries-item__bottom-img mr-20">
//       <img src="assets/img/country-img/flag1.png" alt="" />
//     </div>
//     <div className="countries-item__bottom-content">
//       <h5 className="countries-item__bottom-content-title">
//         <a href="united-states.html">United States</a>
//       </h5> 
//       <p>58 Universities</p>
//     </div>
//   </div>
// </div> 
// <div className="countries-item img-top">
//   <div className="countries-item__top">
//     <div className="countries-item__top-img">
//       <img src="assets/img/country-img/c-2.jpg" alt="" />
//       <div className="countries-item__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="countries-item__bottom d-flex align-items-center">
//     <div className="countries-item__bottom-img mr-20">
//       <img src="assets/img/country-img/flag2.png" alt="" />
//     </div>
//     <div className="countries-item__bottom-content">
//       <h5 className="countries-item__bottom-content-title">
//         <a href="united-states.html">New Zealand</a>
//       </h5> 
//       <p>25 Universities</p>
//     </div>
//   </div>
// </div> 
// <div className="countries-item img-top">
//   <div className="countries-item__top">
//     <div className="countries-item__top-img">
//       <img src="assets/img/country-img/c-3.jpg" alt="" />
//       <div className="countries-item__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="countries-item__bottom d-flex align-items-center">
//     <div className="countries-item__bottom-img mr-20">
//       <img src="assets/img/country-img/flag3.png" alt="" />
//     </div>
//     <div className="countries-item__bottom-content">
//       <h5 className="countries-item__bottom-content-title">
//         <a href="united-states.html">United Kingdom</a>
//       </h5> 
//       <p>33 Universities</p>
//     </div>
//   </div>
// </div> 
// <div className="countries-item img-top">
//   <div className="countries-item__top">
//     <div className="countries-item__top-img">
//       <img src="assets/img/country-img/c-4.jpg" alt="" />
//       <div className="countries-item__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="countries-item__bottom d-flex align-items-center">
//     <div className="countries-item__bottom-img mr-20">
//       <img src="assets/img/country-img/flag4.png" alt="" />
//     </div>
//     <div className="countries-item__bottom-content">
//       <h5 className="countries-item__bottom-content-title">
//         <a href="united-states.html">Switzerland</a>
//       </h5> 
//       <p>38 Universities</p>
//     </div>
//   </div>
// </div> 
// <div className="countries-item img-top">
//   <div className="countries-item__top">
//     <div className="countries-item__top-img">
//       <img src="assets/img/country-img/c-5.jpg" alt="" />
//       <div className="countries-item__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="countries-item__bottom d-flex align-items-center">
//     <div className="countries-item__bottom-img mr-20">
//       <img src="assets/img/country-img/flag5.png" alt="" />
//     </div>
//     <div className="countries-item__bottom-content">
//       <h5 className="countries-item__bottom-content-title">
//         <a href="united-states.html">Australia</a>
//       </h5> 
//       <p>33 Universities</p>
//     </div>
//   </div>
// </div> 
// </div>