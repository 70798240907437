/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import tauthor from '../../assets/img/testimonial/tauthor-1.png';

export default () => {
  
    return (
// className "owl-theme" is optional
<OwlCarousel className='owl-theme testimonail2-active   text-center testi-pad' items={1}   loop margin={10} nav>
<div className="testimonail__wrapper testimonail__wrapper2">
                    <div className="testimonail__header">
                      <div className="testimonail__header__img mb-25">
                        <img src={tauthor} style={{width: '60px'}} alt="" />
                      </div> 
                      <div className="testimonail__header__content mb-35">
                        <h4>Karlosh Tremon</h4>
                        <p>Student</p>
                      </div> 
                    </div>
                    <div className="testimonail__body mb-35">
                      <p>Travellers from countries categorized under the high-risk list who are eligible to enter Germany, aged 12 and older, are obliged to present their vaccination certificates. There are many variations of passages of Lorem Ipsum available, 
                        but the majority have suffered</p>
                    </div>
                    <div className="testimonail__footer">
                      <ul>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li>(Switzerland Visa)</li>
                      </ul>
                    </div>
                  </div>
                  <div className="testimonail__wrapper testimonail__wrapper2">
                    <div className="testimonail__header">
                      <div className="testimonail__header__img mb-25">
                        <img src={tauthor} style={{width: '60px'}} alt="" />
                      </div> 
                      <div className="testimonail__header__content mb-35">
                        <h4>Karlosh Tremon</h4>
                        <p>Student</p>
                      </div> 
                    </div>
                    <div className="testimonail__body mb-35">
                      <p>Travellers from countries categorized under the high-risk list who are eligible to enter Germany, aged 12 and older, are obliged to present their vaccination certificates. There are many variations of passages of Lorem Ipsum available, 
                        but the majority have suffered</p>
                    </div>
                    <div className="testimonail__footer">
                      <ul>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li>(Switzerland Visa)</li>
                      </ul>
                    </div>
                  </div>
</OwlCarousel>
 );
};

// <div className="countries-active  owl-carousel">
// <div className="countries-item img-top">
//   <div className="countries-item__top">
//     <div className="countries-item__top-img">
//       <img src="assets/img/country-img/c-2.jpg" alt="" />
//       <div className="countries-item__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="countries-item__bottom d-flex align-items-center">
//     <div className="countries-item__bottom-img mr-20">
//       <img src="assets/img/country-img/flag1.png" alt="" />
//     </div>
//     <div className="countries-item__bottom-content">
//       <h5 className="countries-item__bottom-content-title">
//         <a href="united-states.html">United States</a>
//       </h5> 
//       <p>58 Universities</p>
//     </div>
//   </div>
// </div> 
// <div className="countries-item img-top">
//   <div className="countries-item__top">
//     <div className="countries-item__top-img">
//       <img src="assets/img/country-img/c-2.jpg" alt="" />
//       <div className="countries-item__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="countries-item__bottom d-flex align-items-center">
//     <div className="countries-item__bottom-img mr-20">
//       <img src="assets/img/country-img/flag2.png" alt="" />
//     </div>
//     <div className="countries-item__bottom-content">
//       <h5 className="countries-item__bottom-content-title">
//         <a href="united-states.html">New Zealand</a>
//       </h5> 
//       <p>25 Universities</p>
//     </div>
//   </div>
// </div> 
// <div className="countries-item img-top">
//   <div className="countries-item__top">
//     <div className="countries-item__top-img">
//       <img src="assets/img/country-img/c-3.jpg" alt="" />
//       <div className="countries-item__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="countries-item__bottom d-flex align-items-center">
//     <div className="countries-item__bottom-img mr-20">
//       <img src="assets/img/country-img/flag3.png" alt="" />
//     </div>
//     <div className="countries-item__bottom-content">
//       <h5 className="countries-item__bottom-content-title">
//         <a href="united-states.html">United Kingdom</a>
//       </h5> 
//       <p>33 Universities</p>
//     </div>
//   </div>
// </div> 
// <div className="countries-item img-top">
//   <div className="countries-item__top">
//     <div className="countries-item__top-img">
//       <img src="assets/img/country-img/c-4.jpg" alt="" />
//       <div className="countries-item__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="countries-item__bottom d-flex align-items-center">
//     <div className="countries-item__bottom-img mr-20">
//       <img src="assets/img/country-img/flag4.png" alt="" />
//     </div>
//     <div className="countries-item__bottom-content">
//       <h5 className="countries-item__bottom-content-title">
//         <a href="united-states.html">Switzerland</a>
//       </h5> 
//       <p>38 Universities</p>
//     </div>
//   </div>
// </div> 
// <div className="countries-item img-top">
//   <div className="countries-item__top">
//     <div className="countries-item__top-img">
//       <img src="assets/img/country-img/c-5.jpg" alt="" />
//       <div className="countries-item__top-img-link">
//         <a href="united-states.html"><i className="fal fa-plus" /></a>
//       </div>
//     </div>
//   </div>
//   <div className="countries-item__bottom d-flex align-items-center">
//     <div className="countries-item__bottom-img mr-20">
//       <img src="assets/img/country-img/flag5.png" alt="" />
//     </div>
//     <div className="countries-item__bottom-content">
//       <h5 className="countries-item__bottom-content-title">
//         <a href="united-states.html">Australia</a>
//       </h5> 
//       <p>33 Universities</p>
//     </div>
//   </div>
// </div> 
// </div>