

import React, { useState, useEffect } from 'react';
import {Link } from "react-router-dom";
import logo from '../../assets/img/logo/logo.png';
import 'animate.css';

const Header=()=>{

    const [count, setCounter] = useState(false);

  useEffect(() => {

  },[]);

  const setCount = () => {
    setCounter(true);

};
    return(
        <>  
        
        <div>
        <header>
          <div className="header-top">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xxl-8 col-lg-6">
                  <div className="header-top-left">
                    <ul>
                      <li><span>Opening Time :</span> 8:30 AM - 9:30 PM</li>
                    </ul>
                  </div>
                </div>
                <div className="col-xxl-4 col-lg-6">
                  <div className="topheader-info">
                    <div className="top-button f-right ">
                      <a href="contact.html">Apply Now</a>
                    </div>
                   
                  </div>                  
                </div>
              </div>
            </div>
          </div>
          <div className="header-menu header-sticky">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xxl-2 col-xl-2 col-lg-2 col-8">
                  <div className="header-logo ">
                    <Link to="/"><img src={logo} className="img-fluid" alt="img" /></Link>
                  </div>
                </div>
                <div className="col-xxl-7 col-xl-7 col-lg-7 col-4">
                  <div className="main-menu d-none d-lg-block ">
                    <nav id="mobile-menu">
                      <ul>
                        <li><Link to="/">HOME</Link>
                        </li>
                        <li><Link to="/services">Services</Link>
                        </li>
                   
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/Countries">Countries</Link></li>
                        <li><Link to="/faq">Faq</Link></li>
                        <li><Link to="/news">News</Link></li>
                        <li><Link to="/contact">CONTACT</Link></li>
                        {/* <li class="menu-item-has-children"><a href="#">PAGES</a>
                                    <ul class="sub-menu">
                                        <li><a href="about.html">About</a></li>
                                        <li><a href="team.html">Team</a></li>
                                        <li><a href="team-details.html">Team Details</a></li>
                                        <li><a href="countries.html">Countries</a></li>
                                        <li><a href="united-states.html">Country Details</a></li>
                                        <li><a href="partners.html">Partners</a></li>
                                        <li><a href="faq.html">Faq</a></li>
                                        <li><a href="404-error.html">404 Error</a></li>
                                    </ul>
                                </li> */}
                        {/* <li class="menu-item-has-children"><a href="news.html">News</a>
                                    <ul class="sub-menu">
                                        <li><a href="news.html">News</a></li>
                                        <li><a href="news-details.html">News Details</a></li>
                                    </ul>
                                </li>
                                <li><a href="contact.html">CONTACT</a></li> */}
                      </ul>
                    </nav>
                  </div>
                  <div className="side-menu-icon d-lg-none text-end" >
                    <button className="side-toggle" onClick={setCount}><i className="far fa-bars" /></button>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3">
                  <div className="main-menu-wrapper d-flex align-items-center justify-content-end">
                    <div className="main-menu-wrapper__search text-left">
                      <a className="search-btn nav-search search-trigger" href="#"><i className="fal fa-search" /></a>
                    </div>
                    <div className="main-menu-wrapper__call-number d-flex align-items-center">
                      <div className="main-menu-wrapper__call-icon mr-10">
                        <img src="assets/img/menu-icon/chatting.png" alt="" />
                      </div>
                      <div className="main-menu-wrapper__call-text">
                        <span>Contact Us</span>
                        <h5><a href="tel:(555)58023059">(555) 5802 3059</a></h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         <div className={count === true ? ' offcanvas-overlay overlay-open': 'offcanvas-overlay'} />
          <div className="fix">
            <div  className={count === true ? 'side-info info-open': 'side-info'}>
              <button className="side-info-close"><i className="fal fa-times" /></button>
              <div className="side-info-content">
        <div className="mobile-menu mean-container"><div className="mean-bar"><a href="#nav" className="meanmenu-reveal" style={{right: '0px', left: 'auto', display: 'inline'}}><span><span><span /></span></span></a><nav className="mean-nav">
              <ul style={{display: 'none'}}>
                <li><a href="index.html">HOME</a>
                </li>
                <li><a href="services.html">Services</a>
                </li>
                {/* <li class="menu-item-has-children"><a href="services.html">Services</a>
                                        <ul class="sub-menu">
                                            <li><a href="services.html">Services</a></li>
                                            <li><a href="business-visa.html">Services Details</a></li>
                                        </ul>
                                    </li> */}
                <li><a href="about.html">About</a></li>
                <li><a href="countries.html">Countries</a></li>
                <li><a href="faq.html">Faq</a></li>
                <li><a href="news.html">News</a></li>
                <li className="mean-last"><a href="contact.html">CONTACT</a></li>
                {/* <li class="menu-item-has-children"><a href="#">PAGES</a>
                                    <ul class="sub-menu">
                                        <li><a href="about.html">About</a></li>
                                        <li><a href="team.html">Team</a></li>
                                        <li><a href="team-details.html">Team Details</a></li>
                                        <li><a href="countries.html">Countries</a></li>
                                        <li><a href="united-states.html">Country Details</a></li>
                                        <li><a href="partners.html">Partners</a></li>
                                        <li><a href="faq.html">Faq</a></li>
                                        <li><a href="404-error.html">404 Error</a></li>
                                    </ul>
                                </li> */}
                {/* <li class="menu-item-has-children"><a href="news.html">News</a>
                                    <ul class="sub-menu">
                                        <li><a href="news.html">News</a></li>
                                        <li><a href="news-details.html">News Details</a></li>
                                    </ul>
                                </li>
                                <li><a href="contact.html">CONTACT</a></li> */}
              </ul>
            </nav></div></div>
      </div>
            </div>
          </div>
        </header>
        {/* Fullscreen search */}
        <div className="search-wrap">
          <div className="search-inner">
            <i className="fal fa-times search-close" id="search-close" />
            <div className="search-cell">
              <form method="get">
                <div className="search-field-holder">
                  <input type="search" className="main-search-input" placeholder="Search Entire Store..." />
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* end fullscreen search */}
        {/* header area end here */}
       
      </div>
        
       </>
    )
}
export default Header;
