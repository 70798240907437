import React from 'react';

import {Link } from "react-router-dom";

import pt from '../../assets/img/about-us/ab-us.jpg';
import about from '../../assets/img/services/services-1.jpg';

import abouttwo from '../../assets/img/featurs/featurs-1.jpg';

import services from '../../assets/img/featurs/services.jpg';

import aboutus from '../../assets/img/about-us/ab-m.jpg';

import Slide from './Sliders-two'

const Main = () => {
  return (
  
<div>
      {/* Fullscreen search */}
      <div className="search-wrap">
          <div className="search-inner">
            <i className="fal fa-times search-close" id="search-close" />
            <div className="search-cell">
              <form method="get">
                <div className="search-field-holder">
                  <input type="search" className="main-search-input" placeholder="Search Entire Store..." />
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* end fullscreen search */}
        {/* header area end here */}
        {/* page title area start */}
        <div className="page-title__area pt-110" style={{backgroundImage: "url(" + pt + ")"}}>
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-wrapper text-center">
                  <h3 className="pb-100">Contact Us</h3>
                </div>
              </div>
            </div>
          </div>
          <nav className="breadccrumb-bg">
            <ul className="breadcrumb justify-content-center pt-20 pb-20">
            <li className="bd-items"><Link to="/">Home</Link></li>
              <li className="bd-items bdritems">|</li>
              <li className="bd-items"> <Link to="/contact">Contact Us</Link></li>
            </ul>
          </nav>
        </div>
        {/* page title area end */}
        <main>
          {/* Contact  area start */}
          <div className="contact__area">
            <div className="contact__vmap">
              <iframe src="https://maps.google.com/maps?hl=en&q=Dhaka+()&ie=UTF8&t=&z=10&iwloc=B&output=embed" />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6" />
                <div className="col-xl-6 col-xl-6 col-lg-6">
                  <div className="contact__form pt-110">
                    <h2 className="contact-form__title">
                      Get in <span>Touch </span>
                    </h2>
                    <form id="contact-form" action="assets/mail.php" method="POST">
                      <div className="row">
                        <div className="col-xl-6">
                          <input name="name" className="contact__input" type="text" placeholder="Your Name" />
                        </div>
                        <div className="col-xl-6">
                          <input name="email" className="contact__input" type="email" placeholder="Your Email" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-6">
                          <input name="phone" className="contact__input" type="text" placeholder="Phone" />
                        </div>
                        <div className="col-xl-6">
                          <input name="subject" className="contact__input" type="text" placeholder="Subject" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-12">
                          <select className="contact__input" name="contact-select">
                            <option value="subject">Subject</option>
                            <option value="subject-1">Option 1</option>
                            <option value="subject-2">Option 2</option>
                            <option value="subject-3">Option 3</option>
                            <option value="subject-4">Option 4</option>
                            <option value="subject-5">Option 5</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-12">
                          <textarea name="message" className="contact__input txt-area " cols={30} rows={10} placeholder="Message" defaultValue={""} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-12">
                          <button className="theme-btn" type="submit">Submit Now</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          {/* Contact  area end */}
          {/* Contact group info start */}
          <div className="contact-group-area pb-70 pt-145">
            <div className="container">
              <div className="row">
                <div className="col-xxl-4 col-xl-6 col-lg-6 ">
                  <div className="contact__gpinfo grey-soft2-bg mb-50">
                    <div className="contact__gpinfo-icon text-center">
                      <i className="flaticon-pin" />
                    </div>
                    <div className="contact__gpinfo-content">
                      <h3 className="contact__gpinfo-content-title text-center mb-25">
                        New York Office
                      </h3>
                      <ul>
                        <li><a href="contact.html"> <span>Address</span>: <p>7005 Colorado Springs, NY</p></a></li>
                        <li><a href="mailto:info@example.com"> <span>Email</span>: <p> info@example.com</p></a></li>
                        <li><a href="tel:+1980589360"> <span>Phone</span>: <p>+1 980 589 360</p> </a></li>
                        <li><a href><span>Opening</span>: <p>Sun - Thu : 10 AM - 10 PM</p> </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-lg-6">
                  <div className="contact__gpinfo grey-soft2-bg mb-50">
                    <div className="contact__gpinfo-icon text-center">
                      <i className="flaticon-pin" />
                    </div>
                    <div className="contact__gpinfo-content">
                      <h3 className="contact__gpinfo-content-title text-center mb-25">
                        Washington Office
                      </h3>
                      <ul>
                        <li><a href="contact.html"> <span>Address</span>: <p>7841 Budapast, Harry, WT</p></a></li>
                        <li><a href="mailto:info@example.com"> <span>Email</span>: <p> info@example.com</p></a></li>
                        <li><a href="tel:+2980589360"> <span>Phone</span>: <p>+2 980 589 360</p> </a></li>
                        <li><a href><span>Opening</span>: <p>Sun - Thu : 10 AM - 10 PM</p> </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-lg-6">
                  <div className="contact__gpinfo grey-soft2-bg mb-50">
                    <div className="contact__gpinfo-icon text-center">
                      <i className="flaticon-pin" />
                    </div>
                    <div className="contact__gpinfo-content">
                      <h3 className="contact__gpinfo-content-title text-center mb-25">
                        Chicago Office
                      </h3>
                      <ul>
                        <li><a href="contact.html"> <span>Address</span>: <p>7005 Colorado Springs, NY</p></a></li>
                        <li><a href="mailto:info@example.com"> <span>Email</span>: <p> info@example.com</p></a></li>
                        <li><a href="tel:+3980589360"> <span>Phone</span>: <p>+3 980 589 360</p> </a></li>
                        <li><a href><span>Opening</span>: <p>Sun - Thu : 10 AM - 10 PM</p> </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Contact group info end */}
          {/* abbrand-area start */}
          <div className="abbrand-area pb-120 wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
            <div className="container">
     
            < Slide />
             
            </div>
          </div>
          {/* abbrand-area end */}
        </main>
      </div>
         
  );
};

export default Main;
