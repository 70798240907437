
import Footer from '../../TheamLayout/Footer';
import Header from '../../TheamLayout/Header';
import Main from './Main'

const News = () => {
  return <>
   <Header/>
  <Main/>
  <Footer/>
  </>;
};

export default News;
