import React, { useState, useEffect } from 'react';
import Sliders from './Sliders';
import Slide from './Sliders-two';

import Slidersthree from './Sliders-three';
import heroOne from '../../assets/img/hero2/hero-1.jpg';
import heroTwo from '../../assets/img/hero2/hero-2.jpg';
import seal from '../../assets/img/about-2/seal.png';
import steps from '../../assets/img/step/steps-br.png';
import mapp from '../../assets/img/team/map-.png';

import partnersOne from '../../assets/img/partners/07.jpg';
 import partnersTwo from '../../assets/img/country-img/c-1.jpg';
 
import cfou from '../../assets/img/country-img/c-5.jpg';
 import pt from '../../assets/img/partners/pt-1.png';

const Main = () => {
  return (
    <main>
          {/* hero area start here */}
       
          <Sliders/>
        
          {/* hero area end here */}
          {/* visa area start here */}
          <section className="visa-area ">
            <div className="container">
              <div className="row g-0 theme-bg visa-top">
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="visa__items br-none">
                    <div className="visa__items-single d-flex align-items-center">
                      <div className="visa__items-single-icon">
                        <i className="flaticon-passport" />
                      </div>
                      <h4 className="visa__items-single-title">
                        <a href="business-visa.html"> Online Visa
                          Application</a>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="visa__items">
                    <div className="visa__items-single d-flex align-items-center">
                      <div className="visa__items-single-icon">
                        <i className="flaticon-content" />
                      </div>
                      <h4 className="visa__items-single-title">
                        <a href="business-visa.html"> Visa 
                          Information</a>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="visa__items">
                    <div className="visa__items-single d-flex align-items-center">
                      <div className="visa__items-single-icon">
                        <i className="flaticon-customer" />
                      </div>
                      <h4 className="visa__items-single-title">
                        <a href="business-visa.html">Immigration 
                          Resources</a>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="visa__items">
                    <div className="visa__items-single d-flex align-items-center">
                      <div className="visa__items-single-icon">
                        <i className="flaticon-passport-1" />
                      </div>
                      <h4 className="visa__items-single-title">
                        <a href="business-visa.html">Online 
                          Passport Application</a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* visa area start here */}
          {/* About-2 area start here */}
          <section className="about-area-2 pt-90 pb-85 wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
            <div className="container">
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 mb-30">
                  <div className="about2-left d-flex">
                    <div className="about2-left__img1 mr-10">
                      <img src={heroOne} style={{width: '362px', height: '512px'}} alt="" />
                    </div>
                    <div className="about2-left__img2">
                      <img src={heroTwo} alt="" style={{width: '262px', height: '512px'}} />
                      <div className="about2-left__info d-flex align-items-center">
                        <div className="about2-left__info__left mr-15">
                          <img src={seal} alt="" />
                        </div>
                        <div className="about2-left__info__right">
                          <h4>ISO Certified</h4>
                          <p>1990-2000</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 mb-30">
                  <div className="section_title_wrapper">
                    <span className="subtitle">
                      About visapass
                    </span>                       
                    <h2 className="section-title about-span mb-30">
                      <span>25+</span>  Years of Your Trust <br /> and Recommendation
                    </h2>
                    <div className="section_title_wrapper__about-content mb-40">
                      <p>For the last 25 years, We have helped students, business persons, tourists, clients with medical needs. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration</p>
                    </div>
                  </div>
                  <div className="about-trust">
                    <div className="row">
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div className="about2__item d-flex  mb-20 mr-20">
                          <div className="about2__icon">
                            <i className="flaticon-fair-trade" />
                          </div>
                          <div className="about2__content">
                            <h4>Trusted by Millions</h4>
                            <p>Most trusted &amp; recommended by millions of students</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div className="about2__item about2-border d-flex  mb-30 mr-20">
                          <div className="about2__icon">
                            <i className="flaticon-trophy" />
                          </div>
                          <div className="about2__content">
                            <h4>Awards Winner</h4>
                            <p>Most trusted &amp; recommended by millions of students</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a href="about.html" className="theme-btn">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* About-2 area end here */}
          {/* Steps-Area start*/}
          <section className="steps-area pb-80 wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
            <div className="container">
              <div className="steps-br">
                <img src={steps} alt="" />
              </div>
              <div className="row">
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="steps-box text-center mb-30">
                    <div className="steps-box__icon mb-35">
                      <span className="icon flaticon-explore" />
                    </div>
                    <div className="steps-box__content">
                      <h4 className="mb-25"><a href="about.html">Explore Scope</a></h4>
                      <p>You can browse more than 71.000 Master’s programmes from all over the world.</p>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="steps-box text-center mb-30">
                    <div className="steps-box__icon mb-35">
                      <span className="icon flaticon-ab-testing" />
                    </div>
                    <div className="steps-box__content">
                      <h4 className="mb-25"><a href="about.html">Compare Chances</a></h4>
                      <p>Make a wishlist of your favorite programmes, check your fit with universities..</p>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="steps-box text-center mb-30">
                    <div className="steps-box__icon mb-35">
                      <span className="icon flaticon-target" />
                    </div>
                    <div className="steps-box__content">
                      <h4 className="mb-25"><a href="about.html">Take Decision</a></h4>
                      <p>Now you have your top programs   shortlisted, you can pick the ones that fit you the best.</p>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                  <div className="steps-box text-center mb-30">
                    <div className="steps-box__icon mb-35">
                      <span className="icon flaticon-tap" />
                    </div>
                    <div className="steps-box__content">
                      <h4 className="mb-25"><a href="about.html">Apply Goals</a></h4>
                      <p>When can you feel confident about your program choice, now you can apply confidently.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>                       
          {/* Steps-Area end*/}
          {/* Browse -Area start*/}
          <section className="browse-area pt-110 pb-90 grey-soft2-bg">
            <div className="container">
              <div className="row justify-content-center wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
                <div className="col-xxl-10">
                  <div className="section_title_wrapper text-center mb-50">
                    <span className="subtitle">
                      Browse Courses
                    </span>                       
                    <h2 className="section-title">
                      Browse All the Courses
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30  wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}> 
                  <div className="browse-box ">
                    <div className="browse__wrapper">
                      <h4 className="browse__wrapper__title mb-25"><a href="coaching.html">Diploma Programs</a></h4>
                      <p className="browse__wrapper__subtitle mb-25">Diploma programs with the other family based employment based and investment finally has  got immigration with job.</p>
                      <a className="browse-link read-more" href="coaching.html">View Programs <i className="fal fa-long-arrow-right" /></a>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30  wow fadeInUp" data-wow-delay="0.4s" style={{visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp'}}> 
                  <div className="browse-box ">
                    <div className="browse__wrapper">
                      <h4 className="browse__wrapper__title mb-25"><a href="coaching.html">Undergraduate Programs</a></h4>
                      <p className="browse__wrapper__subtitle mb-25">Undergraduate program in higher education is designed for early-career professionals seeking to make an impact </p>
                      <a className="browse-link read-more" href="coaching.html">View Programs <i className="fal fa-long-arrow-right" /></a>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30  wow fadeInUp" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp'}}> 
                  <div className="browse-box ">
                    <div className="browse__wrapper">
                      <h4 className="browse__wrapper__title mb-25"><a href="coaching.html">Graduate Programs</a></h4>
                      <p className="browse__wrapper__subtitle mb-25">Graduate program in higher education is for early-career professionals seeking to make an impact on the student affairs track</p>
                      <a className="browse-link read-more" href="coaching.html">View Programs <i className="fal fa-long-arrow-right" /></a>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30  wow fadeInUp" data-wow-delay="0.6s" style={{visibility: 'visible', animationDelay: '0.6s', animationName: 'fadeInUp'}}> 
                  <div className="browse-box ">
                    <div className="browse__wrapper">
                      <h4 className="browse__wrapper__title mb-25"><a href="coaching.html">Postgraduate Programs</a></h4>
                      <p className="browse__wrapper__subtitle mb-25">Postgraduate program in higher education is for early-career professionals seeking to make an impact on the student</p>
                      <a className="browse-link read-more" href="coaching.html">View Programs <i className="fal fa-long-arrow-right" /></a>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30  wow fadeInUp" data-wow-delay="0.7s" style={{visibility: 'visible', animationDelay: '0.7s', animationName: 'fadeInUp'}}> 
                  <div className="browse-box ">
                    <div className="browse__wrapper">
                      <h4 className="browse__wrapper__title mb-25"><a href="coaching.html">Research Programs</a></h4>
                      <p className="browse__wrapper__subtitle mb-25">Research program in higher education is for early-career professionals seeking to make an impact on the student </p>
                      <a className="browse-link read-more" href="coaching.html">View Programs <i className="fal fa-long-arrow-right" /></a>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-30  wow fadeInUp" data-wow-delay="0.8s" style={{visibility: 'visible', animationDelay: '0.8s', animationName: 'fadeInUp'}}> 
                  <div className="browse-box ">
                    <div className="browse__wrapper">
                      <h4 className="browse__wrapper__title mb-25"><a href="coaching.html">PHD Programs</a></h4>
                      <p className="browse__wrapper__subtitle mb-25">PHD program in higher education is designed for early-career professionals seeking to make an impact on the student affairs track</p>
                      <a className="browse-link read-more" href="coaching.html">View Programs <i className="fal fa-long-arrow-right" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Browse -Area end*/}
          {/* call to action 2 start*/}
          <section className="callto-action-2 d-flex align-items-center theme-bg wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
            <div className="container">
              <div className="row"> 
                <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8">
                  <div className="callto-title">
                    <h3>Find out which programs match your personality!</h3>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4">
                  <div className="callto-btn text-right">
                    <a href="contact.html" className="theme-btn clto-btn-2">Take a free test</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* call to action 2 end*/}
          {/* Globall-2 area start */}
          <section className="global-area pt-120 wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
            <div className="container">
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="section_title_wrapper global-text mb-30">
                    <span className="subtitle">
                      Credit Transfer
                    </span>                       
                    <h2 className="section-title">
                      We're Global Partner of <br /> 580+ Universities
                    </h2>
                    <p className="mb-40">We have helped students, business persons, tourists, clients with medical needs to acquire U.S. visas. Besides, we also help with other family and provide counseling services for immigration</p>
                    <a href="partners.html" className="theme-btn">More Agencies</a>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="global-area-img">
                    <img src={mapp} alt="" />
                  </div>
                </div>
              </div>
              <div className="global-border">
                <hr />
              </div>
            </div>
          </section>
          {/* Globall-2 area end */}
          {/* features services start */}
          <section className="features-services pt-80 pb-120  wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
            <div className="container ">
              <div className="row justify-content-center">
                <div className="col-xxl-10">
                  <div className="section_title_wrapper text-center mb-50">
                    <span className="subtitle">
                      Featured Services 
                    </span>                       
                    <h2 className="section-title">
                      Education Services 
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row g-0">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                  <div className="features2">
                    <div className="features2__contant d-flex align-items-center mb-20">
                      <div className="features2__icon mr-15">
                        <i className="flaticon-admission" />
                      </div>
                      <h4 className="features2__title">
                        <a href="services.html"> University Admission</a>
                      </h4>
                    </div>
                    <div className="features2__wrapper">
                      <p className="features2__wrapper__subtitle mb-25">University Admission in higher education is designed for professionals seeking to make an impact on the student affairs track</p>
                      <a className="browse-link read-more" href="services.html">View Programs <i className="fal fa-long-arrow-right" /></a>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                  <div className="features2">
                    <div className="features2__contant d-flex align-items-center mb-20">
                      <div className="features2__icon mr-15">
                        <i className="flaticon-briefcase" />
                      </div>
                      <h4 className="features2__title">
                        <a href="services.html"> Work Permit Process</a>
                      </h4>
                    </div>
                    <div className="features2__wrapper">
                      <p className="features2__wrapper__subtitle mb-25">Work permit approval  in higher education is designed for career professionals seeking to make an impact on the student affairs track</p>
                      <a className="browse-link read-more" href="services.html">View Programs <i className="fal fa-long-arrow-right" /></a>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                  <div className="features2">
                    <div className="features2__contant d-flex align-items-center mb-20">
                      <div className="features2__icon mr-15">
                        <i className="flaticon-hands" />
                      </div>
                      <h4 className="features2__title">
                        <a href="services.html">Health Insurance </a>
                      </h4>
                    </div>
                    <div className="features2__wrapper">
                      <p className="features2__wrapper__subtitle mb-25">Health &amp; Medical in higher education is designed for early-career professionals seeking to make an impact on the student affairs track</p>
                      <a className="browse-link read-more" href="services.html">View Programs <i className="fal fa-long-arrow-right" /></a>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                  <div className="features2">
                    <div className="features2__contant d-flex align-items-center mb-20">
                      <div className="features2__icon mr-15">
                        <i className="flaticon-internship" />
                      </div>
                      <h4 className="features2__title">
                        <a href="services.html"> Overseas Internship</a>
                      </h4>
                    </div>
                    <div className="features2__wrapper">
                      <p className="features2__wrapper__subtitle mb-25">If you want to intern in higher education is designed for early-career professionals seeking to make an impact on the student affairs track</p>
                      <a className="browse-link read-more" href="services.html">View Programs <i className="fal fa-long-arrow-right" /></a>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                  <div className="features2">
                    <div className="features2__contant d-flex align-items-center mb-20">
                      <div className="features2__icon mr-15">
                        <i className="flaticon-education" />
                      </div>
                      <h4 className="features2__title">
                        <a href="services.html"> Education Loan</a>
                      </h4>
                    </div>
                    <div className="features2__wrapper">
                      <p className="features2__wrapper__subtitle mb-25">We manage the loan in higher education is designed for early-career professionals seeking to make an impact on the student affairs track</p>
                      <a className="browse-link read-more" href="services.html">View Programs <i className="fal fa-long-arrow-right" /></a>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                  <div className="features2">
                    <div className="features2__contant d-flex align-items-center mb-20">
                      <div className="features2__icon mr-15">
                        <i className="flaticon-coach" />
                      </div>
                      <h4 className="features2__title">
                        <a href="services.html">Coaching Assistance</a>
                      </h4>
                    </div>
                    <div className="features2__wrapper">
                      <p className="features2__wrapper__subtitle mb-25">Students need help in higher education is designed for early-career professionals seeking to make an impact on the student affairs track</p>
                      <a className="browse-link read-more" href="services.html">View Programs <i className="fal fa-long-arrow-right" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>  
          {/* features services end */} 
          {/* features services start */} 
          <section className="call-to-action3 d-flex align-items-center">
            <div className="container">
              <div className="row theme-bg  g-0 pad-cut pt-40 pb-40 pr-80 pl-80">
                <div className="col-xxl-8 col-xl-8 col-lg-8">
                  <div className="call3-left">
                    <h2>Talk To Advisor For Credit Transfer</h2>
                    <p>By dealing with data, select tools that help you automate time-consuming processes.</p>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 cbr-left d-flex align-items-center justify-content-end">
                  <div className="calltoaction3__wrapper d-flex align-items-center">
                    <div className="calltoaction3__wrapper__icon mr-10">
                      <i className="fal fa-headset" />
                    </div>
                    <div className="calltoaction3__wrapper__call-number">
                      <span>Call for help</span>
                      <h5><a href="tel:+1878298023">+1 878 298 023</a></h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* features services end */} 
          <section className="popular-country-2 pt-200 pb-120"  style={{backgroundImage: "url(" + partnersTwo + ")"}}>
            <div className="container">
              <div className="row pb-10">
                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-6">
                  <div className="section_title_wrapper mb-30">
                    <span className="subtitle">
                      Popular Country
                    </span>                       
                    <h2 className="section-title">
                      Famous Countries
                    </h2>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 d-flex align-items-center justcont-cn justify-content-end">
                  <div className="calltoaction-btn text-right">
                    <a href className="theme-btn blacks-hover">Check Eligibility</a>
                  </div>
                </div>
              </div>
              <div className="famous-countries">

              <Slide/>
             
              </div>
            </div>
          </section>
          {/* Our Partners 2 start */}
          <section className="partners-area pt-120" style={{}}>
            <div className="container">
              <div className="row ">
                <div className="col-xxl-6 col-xl-6 col-lg-6">
                  <div className="section_title_wrapper partners-65 mb-30">
                    <span className="subtitle">
                      Our Partners
                    </span>                       
                    <h2 className="section-title">
                      Our Partner Companies <br />And Institutions
                    </h2>
                    <p className="mt-30 mb-40 ">We have helped students, business persons, tourists, clients with medical needs to acquire U.S. visas. Besides, we also help with other family and provide counseling services for immigration </p>
                    <a href="partners.html" className="theme-btn partner-btn">See All Partners</a>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6">
                  <div className="row g-0">
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                      <div className="partner-img">
                        <img src={pt} alt="" />
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                      <div className="partner-img">
                        <img src={pt} alt="" />
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                      <div className="partner-img">
                        <img src={pt} alt="" />
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                      <div className="partner-img">
                        <img src={pt} alt="" />
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                      <div className="partner-img">
                        <img src={pt} alt="" />
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                      <div className="partner-img">
                        <img src={pt} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="fact-area-2 pt-80 pb-45">
                <div className="row">
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
                    <div className="fact fact-2 text-center">
                      <h1 className="counter-count"><span className="counter">25</span>k+</h1>
                      <span>Happy Clients &amp; Students</span>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
                    <div className="fact fact-2 text-center ">
                      <h1 className="counter-count"><span className="counter">80</span>+</h1>
                      <span>Countries Affiliation</span>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
                    <div className="fact fact-2 text-center ">
                      <h1 className="counter">360</h1>
                      <span>Top University Partner</span>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 mb-30">
                    <div className="fact fact-2 text-center ">
                      <h1 className="counter-count"><span className="counter">23</span>k+</h1>
                      <span>Visa &amp; Immigration</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Our Partners 2 end */}
          {/* Testimonial 2 start */}
          <div className="testimonial-2 pt-110 pb-135 d-flex align-items-center" style={{backgroundImage: "url(" + partnersOne + ")"}}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xxl-10">
                  <div className="section_title_wrapper text-center mb-20">
                    <span className="subtitle">
                      Testimonials
                    </span>                       
                    <h2 className="section-title">
                      What Clients Say About Us and <br /> Our Services
                    </h2>

                  </div>
                </div>
              </div>
              <div className="row">
              <Slidersthree/>
                <div className="testimonail2-active  owl-carousel text-center testi-pad">
                  <div className="testimonail__wrapper testimonail__wrapper2">
                    <div className="testimonail__header">
                      <div className="testimonail__header__img mb-25">
                        <img src="assets/img/testimonial/tauthor-1.png" style={{width: '60px'}} alt="" />
                      </div> 
                      <div className="testimonail__header__content mb-35">
                        <h4>Karlosh Tremon</h4>
                        <p>Student</p>
                      </div> 
                    </div>
                    <div className="testimonail__body mb-35">
                      <p>Travellers from countries categorized under the high-risk list who are eligible to enter Germany, aged 12 and older, are obliged to present their vaccination certificates. There are many variations of passages of Lorem Ipsum available, 
                        but the majority have suffered</p>
                    </div>
                    <div className="testimonail__footer">
                      <ul>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li>(Switzerland Visa)</li>
                      </ul>
                    </div>
                  </div>
                  <div className="testimonail__wrapper testimonail__wrapper2">
                    <div className="testimonail__header">
                      <div className="testimonail__header__img mb-25">
                        <img src="assets/img/testimonial/tauthor-1.png" alt="" />
                      </div> 
                      <div className="testimonail__header__content mb-35">
                        <h4>Daniel Hasmass</h4>
                        <p>Manager</p>
                      </div> 
                    </div>
                    <div className="testimonail__body mb-35">
                      <p>Travellers from countries categorized under the high-risk list who are eligible to enter Germany, aged 12 and older, are obliged to present their vaccination certificates. There are many variations of passages of Lorem Ipsum available, 
                        but the majority have suffered</p>
                    </div>
                    <div className="testimonail__footer">
                      <ul>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li>(Switzerland Visa)</li>
                      </ul>
                    </div>
                  </div>
                  <div className="testimonail__wrapper testimonail__wrapper2">
                    <div className="testimonail__header">
                      <div className="testimonail__header__img mb-25">
                        <img src="assets/img/testimonial/tauthor-1.png" alt="" />
                      </div> 
                      <div className="testimonail__header__content mb-35">
                        <h4>Narayan Kamora</h4>
                        <p>Senior Lawyer</p>
                      </div> 
                    </div>
                    <div className="testimonail__body mb-35">
                      <p>Travellers from countries categorized under the high-risk list who are eligible to enter Germany, aged 12 and older, are obliged to present their vaccination certificates. There are many variations of passages of Lorem Ipsum available, 
                        but the majority have suffered</p>
                    </div>
                    <div className="testimonail__footer">
                      <ul>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li><i className="fas fa-star" /></li>
                        <li>(Switzerland Visa)</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Testimonial 2 end */}
          {/* Blog 2 start */}
          <section className="blog2-area- pt-110 pb-90 wow fadeInUp" data-wow-delay="0.3s" style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp'}}>
            <div className="container">
              <div className="row d-flex align-items-center">
                <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6">
                  <div className="section_title_wrapper mb-50">
                    <span className="subtitle">
                      Recent Blog
                    </span>                       
                    <h2 className="section-title">
                      Recent Updates of Visa <br /> And Immagration
                    </h2>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-30">
                  <div className="blog-button">
                    <a href="news.html" className="theme-btn blog-btn">Read All Posts</a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-6 col-xl-6">
                  <article className="blog-2 d-flex align-items-center mb-30">
                    <div className="blog__thumb2 mr-30" style={{width: '271px', height: '240px'}}>
                      <a href="news-details.html"><img src={cfou} style={{width: '271px', height: '240px'}} alt="" /></a> 
                    </div>
                    <div className="blog2__content">
                      <div className="blog-meta blog2-meta mb-20">
                        <span> <i className="fal fa-calendar-day" /> <a href="news-details.html">visapass</a></span>
                        <span> <i className="far fa-user" />02 feb 2018 </span>
                      </div>
                      <div className="blog-text">
                        <h3 className="blog2__content__title mb-25">
                          <a href="news-details.html">Web Development To Update React Hooks Cons</a> 
                        </h3>
                        <p className="mb-25">We have helped students, business persons, tourists, clients with medical needs......</p>
                        <div className="read-more">
                          <a href="news-details.html">Read More <i className="fal fa-long-arrow-right" /></a>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="col-xxl-6  col-xl-6">
                  <article className="blog-2 d-flex align-items-center mb-30">
                    <div className="blog__thumb2 mr-30" style={{width: '271px', height: '240px'}}>
                      <a href="news-details.html"><img src={cfou} style={{width: '271px', height: '240px'}} alt="" /></a> 
                    </div>
                    <div className="blog2__content">
                      <div className="blog-meta blog2-meta mb-20">
                        <span><i className="fal fa-calendar-day" /> <a href="news-details.html">visapass</a></span>
                        <span> <i className="far fa-user" /> <a href="/">02 feb 2018</a> </span>
                      </div>
                      <div className="blog-text">
                        <h3 className="blog2__content__title mb-25">
                          <a href="/">EU Commission Introduces New Erasmus+ App</a> 
                        </h3>
                        <p className="mb-25">We have helped students, business persons, tourists, clients with medical needs......</p>
                        <div className="read-more">
                          <a href="news-details.html">Read More <i className="fal fa-long-arrow-right" /></a>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </section>  
          {/* Blog 2 end */}
        </main>
  );
};

export default Main;
